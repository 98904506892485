import { combineReducers } from "redux";
import { loginReducer } from "./loginReducer";
import { mailingReducer } from "./mailingReducer";
import { mainReducer } from "./mainReducer";

export default combineReducers({
  login: loginReducer,
  main: mainReducer,
  mailing: mailingReducer,
});
