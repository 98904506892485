export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGOUT = "LOGOUT";
export const LOGOUT_ERROR = "LOGOUT_ERROR";
export const AUTH_ME_ERROR = "AUTH_ME_ERROR";

export const SET_LOADING = "SET_LOADING";

export const SET_USER_DATA = "SET_USER_DATA";
export const SET_NEW_USERS_STATS = "SET_NEW_USERS_STATS";
export const SET_TOTAL_USERS_STATS = "SET_TOTAL_USERS_STATS";
export const SET_EVENTS_DATA = "SET_EVENTS_DATA";

export const GET_MAILINGS = "GET_MAILINGS";
export const CREATE_MAILING_SUCCESS = "CREATE_MAILING_SUCCESS";
export const CREATE_MAILING_ERROR = "CREATE_MAILING_ERROR";
export const GET_MAILING = "GET_MAILING";
export const GET_MAILING_ERROR = "GET_MAILING_ERROR";
export const UPDATE_MAILING_SUCCESS = "UPDATE_MAILING_SUCCESS";
export const UPDATE_MAILING_ERROR = "UPDATE_MAILING_ERROR";
export const CLEAR_CURRENT_MAILING = "CLEAR_CURRENT_MAILING";
