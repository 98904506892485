import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

import classes from "./Tables.module.scss";

export const NewUsers = ({ users }) => {
  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell width={425} align="center">
            Платформа
          </TableCell>
          <TableCell width={425} align="center">
            Количество
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell align="center">Workplace</TableCell>
          <TableCell align="center">{users?.workplace || 0}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
