import React from "react";
import { Field } from "react-final-form";

import CustomTextField from "../UI/CustomTextField";

import classes from "./AddMailingModal.module.scss";

export const CustomField = ({
  placeholder,
  name,
  value,
  onChange,
  component,
  maxLength,
  ...rest
}) => {
  return (
    <Field
      {...rest}
      label={placeholder}
      component={CustomTextField}
      name={name}
      variant="outlined"
      classes={{
        root: classes.input,
      }}
      inputProps={{
        value: value,
        onChange: onChange,
        type: component,
        maxLength,
      }}
      fullWidth
    />
  );
};
