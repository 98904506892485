import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Field } from "react-final-form";
import { Button } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ruLocale from "date-fns/locale/ru";

import { CustomField } from "./CustomField";

import { getMailing } from "../../store/actions/mailingActions";
import { required } from "../../utils/helpers/validators";

import classes from "./AddMailingModal.module.scss";

const DatePickerAdapter = ({
  input: { name },
  inputProps: { onChange, value },
  ...rest
}) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        clearable
        label="Дата"
        minDate={new Date()}
        format="dd.MM.yyyy"
        value={value}
        onChange={onChange}
        name={name}
        required
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
};

const TimePickerAdapter = ({
  input: { name },
  inputProps: { onChange, value },
  ...rest
}) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
      <KeyboardTimePicker
        id="time-picker"
        label="Время"
        KeyboardButtonProps={{
          "aria-label": "change time",
        }}
        value={value}
        onChange={onChange}
        name={name}
        ampm={false}
        required
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
};

const AnswerBlock = ({
  answer,
  onChange,
  onDelete,
  index,
  disabled,
  disabledDelete,
}) => {
  return (
    <div className={classes.answer_item}>
      <CustomField
        placeholder="Введите текст ответа"
        name={`available_answers[${index}]`}
        value={answer}
        onChange={(e) => onChange(index, e)}
        helperText={`${answer?.length ?? 0}/20`}
        maxLength={20}
        disabled={disabled}
      />
      <Button
        onClick={() => onDelete(index)}
        classes={{
          root: classes.btn,
        }}
        disabled={disabled || disabledDelete}
      >
        <DeleteIcon />
      </Button>
    </div>
  );
};

export const AddMailingModal = ({ editedId, onSubmit, onCancel }) => {
  const dispatch = useDispatch();
  const { currentMailing, loading } = useSelector(({ mailing }) => mailing);

  const [formData, setFormData] = React.useState({});
  const [answers, setAnswers] = React.useState([""]);

  const [date, setDate] = React.useState(null);
  const [time, setTime] = React.useState(null);
  const [selectedTime, setSelectedTime] = React.useState(null);

  React.useEffect(() => {
    if (editedId) {
      dispatch(getMailing(editedId));
    }
  }, []);

  React.useEffect(() => {
    const selectedDateTime = new Date(date).setHours(
      new Date(time).getHours(),
      new Date(time).getMinutes(),
      0,
      0
    );

    setSelectedTime(selectedDateTime);
  }, [date, time]);

  React.useEffect(() => {
    if (currentMailing) {
      setFormData({
        ...currentMailing,
        emails: currentMailing.emails.join("; "),
      });

      if (currentMailing.available_answers.length > 0) {
        setAnswers([...currentMailing.available_answers]);
      }

      setDate(new Date(currentMailing.send_time + "Z"));
      setTime(new Date(currentMailing.send_time + "Z"));
    }
  }, [currentMailing]);

  const onAnswerFieldChange = (index, { target }) => {
    const answersValues = answers;

    answersValues[index] = target.value;

    setAnswers(answersValues);
  };

  const addAnswerHandle = () => {
    setAnswers([...answers, ""]);
  };

  const deleteAnswerHandle = (index) => {
    if (answers.length > 1) {
      const answersArray = answers;
      setAnswers([
        ...answersArray.slice(0, index),
        ...answersArray.slice(index + 1),
      ]);
    }
  };

  const onFormDataChange = (event) => {
    const objFields = event.target.name.split(".");
    if (objFields.length > 1) {
      setFormData({
        ...formData,
        [objFields[0]]: { [objFields[1]]: event.target.value },
      });
    } else {
      setFormData({ ...formData, [event.target.name]: event.target.value });
    }
  };

  const beforeSubmit = (values) => {
    const emails = formData.emails
      .split(";")
      .map((email) => email.trim())
      .filter((email) => email.length > 0);

    const availableAnswers = answers.filter((answer) => answer.length > 0);

    onSubmit({
      text: formData.text,
      emails,
      available_answers: availableAnswers,
      send_time: new Date(selectedTime).toISOString(),
    });
  };

  return (
    <div>
      <Form
        onSubmit={beforeSubmit}
        initialValues={{ ...formData }}
        render={({ handleSubmit, invalid }) => {
          return (
            <form onSubmit={handleSubmit} className={classes.form}>
              <div className={classes.input_item}>
                <label className={classes.subtitle}>
                  Выберете дату и время для рассылки
                </label>
                <div className={classes.date_wrapper}>
                  <Field
                    name="date"
                    component={DatePickerAdapter}
                    value={date}
                    inputProps={{
                      value: date,
                      onChange: (event) => setDate(event),
                    }}
                    disabled={loading}
                  />
                  <Field
                    name="time"
                    component={TimePickerAdapter}
                    value={time}
                    inputProps={{
                      value: time,
                      onChange: (event) => setTime(event),
                    }}
                    disabled={loading}
                  />
                </div>
              </div>
              <div className={classes.input_item}>
                <p className={classes.subtitle}>Текст</p>
                <CustomField
                  placeholder="Введите текст рассылки"
                  component="textarea"
                  name="text"
                  value={formData.text}
                  onChange={onFormDataChange}
                  helperText={`${formData.text?.length ?? 0}/640`}
                  maxLength={640}
                  disabled={loading}
                  validate={required}
                />
              </div>
              <div className={classes.input_item}>
                <p className={classes.subtitle}>Список Email сотрудников</p>
                <CustomField
                  placeholder="Введите список Email"
                  name="emails"
                  component="textarea"
                  value={formData.emails}
                  onChange={onFormDataChange}
                  helperText={"Перечислите все emails через точку с запятой"}
                  disabled={loading}
                  validate={required}
                />
              </div>
              <div className={classes.input_item}>
                <p className={classes.subtitle}>Варианты ответа</p>
                {answers.map((answer, index) => (
                  <AnswerBlock
                    key={index}
                    index={index}
                    answer={answer}
                    onChange={onAnswerFieldChange}
                    onDelete={deleteAnswerHandle}
                    disabled={loading}
                    disabledDelete={answers.length === 1}
                  />
                ))}
                <Button
                  classes={{
                    root: classes.add_btn,
                  }}
                  variant="outlined"
                  type="button"
                  onClick={addAnswerHandle}
                  disabled={answers.length >= 3 || loading}
                  disableElevation
                >
                  Добавить ответ
                </Button>
              </div>

              <div className={classes.button_wrapper}>
                <Button
                  variant="contained"
                  type="button"
                  onClick={onCancel}
                  disableElevation
                >
                  Отмена
                </Button>
                <Button
                  classes={{
                    root: classes.submit_btn,
                  }}
                  variant="contained"
                  color="primary"
                  type="submit"
                  disableElevation
                  disabled={loading || invalid || selectedTime < Date.now()}
                >
                  Отправить
                </Button>
              </div>
            </form>
          );
        }}
      />
    </div>
  );
};
