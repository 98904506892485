import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
} from "@material-ui/core";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import MainContainer from "../../components/MainContainer/MainContainer";
import { getMailing, updateMailing } from "../../store/actions/mailingActions";

import classes from "./MailingItem.module.scss";
import { MailingItemRow } from "../../components/Mailing/MailingItemRow/MailingItemRow";
import { ModalWindow } from "../../components/UI/ModalWindow/ModalWindow";
import { AddMailingModal } from "../../components/AddMailingModal/AddMailingModal";

import { getFormatDate, getTimeFromDate } from "../../utils/helpers/formatDate";

export const MailingItem = () => {
  const { id: mailingId } = useParams();

  const dispatch = useDispatch();
  const { currentMailing, loading } = useSelector(({ mailing }) => mailing);

  React.useEffect(() => {
    dispatch(getMailing(mailingId));
  }, []);

  const [isModalOpen, toggleIsModalOpen] = React.useState(false);

  const openModal = () => {
    toggleIsModalOpen(true);
  };

  const closeModal = () => {
    toggleIsModalOpen(false);
  };

  const sendMailing = (values) => {
    dispatch(updateMailing({ id: mailingId, body: values }));

    closeModal();
  };

  return (
    <MainContainer>
      {currentMailing ? (
        <div className={classes.content}>
          <div className={classes.header}>
            <Link to="/mailing" className={classes.back_link}>
              <ArrowBackIcon />
              <span>Назад к рассылкам</span>
            </Link>
            <div className={classes.btn_wrapper}>
              <div className={classes.add_btn}>
                <Button
                  variant="contained"
                  className={classes.btn}
                  onClick={openModal}
                >
                  <span className={classes.btn_text}>Редактировать</span>
                </Button>
              </div>
            </div>
          </div>
          <section className={classes.mailing_info}>
            <Card className={classes.root}>
              <CardContent>
                <Typography
                  className={classes.title}
                  // color="textSecondary"
                  gutterBottom
                >
                  <span>Статус: </span>
                  {`${
                    currentMailing.is_sent
                      ? "Отправлено"
                      : currentMailing.is_sending_now
                      ? "Отправляется"
                      : "Запланировано"
                  }`}
                </Typography>
                <Typography className={classes.title} gutterBottom>
                  <span>Дата и время отправки: </span>
                  {getFormatDate(new Date(currentMailing.send_time + "Z"))}
                  &nbsp;
                  {getTimeFromDate(new Date(currentMailing.send_time + "Z"))}
                </Typography>
                <Typography className={classes.title} gutterBottom>
                  <span>Текст: </span>
                  {`${currentMailing.text}`}
                </Typography>
                {currentMailing.available_answers.length > 0 ? (
                  <Typography className={classes.title} gutterBottom>
                    <span>Возможные ответы: </span>
                    {`${currentMailing.available_answers.join(";\n")}`}
                  </Typography>
                ) : null}
                <Typography className={classes.title} gutterBottom>
                  <span>Адреса сотрудников: </span>
                  {`${currentMailing.emails.join(";\n")}`}
                </Typography>
              </CardContent>
            </Card>
          </section>
          <section className={classes.mailing_table}>
            {currentMailing?.available_answers.length > 0 && (
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell width={280} align="center">
                      Email
                    </TableCell>
                    <TableCell width={280} align="center">
                      Ответ
                    </TableCell>
                    <TableCell width={280} align="center">
                      Дата ответа
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentMailing.answers.length > 0 ? (
                    currentMailing.answers.map((item) => (
                      <MailingItemRow {...item} />
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colspan="3">
                        <div className={classes.no_answers}>
                          <div className={classes.text}>Пока нет ответов</div>
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </section>
        </div>
      ) : null}

      <ModalWindow
        title={"Редактировать рассылку"}
        isOpen={isModalOpen}
        handleClose={closeModal}
      >
        <AddMailingModal onSubmit={sendMailing} onCancel={closeModal} />
      </ModalWindow>
    </MainContainer>
  );
};
