import * as types from "../types";

const initialState = {
  token: null,
  error: null,
  isAuth: false,
};

export function loginReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_USER_DATA:
      return {
        ...state,
        token: action.token,
        error: null,
        isAuth: true,
      };
    case types.LOGOUT:
      return {
        ...state,
        token: null,
        error: null,
        isAuth: false,
      };
    case types.LOGIN_ERROR:
      return { ...state, token: null, isAuth: false, error: action.detail };
    case types.AUTH_ME_ERROR:
      localStorage.removeItem("token");
      return { ...state, token: null, isAuth: false, error: null };
    case types.LOGOUT_ERROR:
      return { ...state, error: action.detail };
    default:
      return state;
  }
}
