import axios from "axios";
import { getWithExpiry } from "../helpers/localStorageWithExpiry";

var ACTUAL_API_URL;

if (process.env.REACT_APP_BACKEND_ADDR) {
  ACTUAL_API_URL = process.env.REACT_APP_BACKEND_ADDR;
} else {
  ACTUAL_API_URL = "https://servicenow.appvelox.ru/api";
}

export const API_URL = ACTUAL_API_URL;

const $api = axios.create({
  withCredentials: true,
  baseURL: API_URL,
});

$api.interceptors.request.use(
  (config) => {
    const token = getWithExpiry("token");
    const tokenType = "Bearer";

    config.headers.Authorization = tokenType + " " + token;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default $api;
