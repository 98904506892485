import $api from "../../utils/http";
import * as types from "../types";

export const getMailings = () => {
  return async (dispatch) => {
    await $api
      .get("/mailings/")
      .then((response) => {
        dispatch({ type: types.GET_MAILINGS, payload: response.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const createMailing = (body) => {
  return async (dispatch) => {
    await $api
      .post("/mailings/", body)
      .then((response) => {
        dispatch({ type: types.CREATE_MAILING_SUCCESS });
        dispatch(getMailings());
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: types.CREATE_MAILING_ERROR, payload: err });
      });
  };
};

export const getMailing = (id) => {
  return async (dispatch) => {
    dispatch({ type: types.SET_LOADING, payload: true });
    await $api
      .get(`/mailings/${id}`)
      .then((response) => {
        dispatch({ type: types.GET_MAILING, payload: response.data });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: types.GET_MAILING_ERROR, payload: err });
      });
    dispatch({ type: types.SET_LOADING, payload: false });
  };
};

export const clearCurrentMailing = () => {
  return async (dispatch) => {
    dispatch({ type: types.CLEAR_CURRENT_MAILING });
  };
};

export const updateMailing = ({ id, body }) => {
  return async (dispatch) => {
    await $api
      .patch(`/mailings/${id}`, body)
      .then((response) => {
        dispatch({ type: types.UPDATE_MAILING_SUCCESS });
        dispatch(getMailing(id));
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: types.UPDATE_MAILING_ERROR, payload: err });
      });
  };
};
