import { TextField, TextareaAutosize } from "@material-ui/core";
import React from "react";

const CustomTextField = ({
  label,
  input,
  meta: { touched, invalid, error },
  ...custom
}) => {
  return custom.inputProps?.type === "textarea" ? (
    <TextField
      {...input}
      {...custom}
      multiline
      minRows={3}
      maxRows={4}
      placeholder={label}
      error={touched && invalid}
      helperText={(touched && error) || custom.helperText}
    />
  ) : (
    <TextField
      {...input}
      {...custom}
      placeholder={label}
      error={touched && invalid}
      helperText={(touched && error) || custom.helperText}
    />
  );
};

export default CustomTextField;
