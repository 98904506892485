import * as types from "../types";

const initialState = {
  newUsers: null,
  totalUsers: null,
  events: null,
};

export function mainReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_TOTAL_USERS_STATS:
      return {
        ...state,
        totalUsers: action.users,
      };
    case types.SET_NEW_USERS_STATS:
      return {
        ...state,
        newUsers: action.users,
      };
    case types.SET_EVENTS_DATA:
      return {
        ...state,
        events: action.events,
      };
    default:
      return state;
  }
}
