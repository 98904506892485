import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LinearProgress } from "@material-ui/core";
import classNames from "classnames/bind";

import { logout } from "../../store/actions/loginActions";

import classes from "./MainContainer.module.scss";

const cx = classNames.bind(classes);

const MainContainer = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const logoutHandler = () => {
    dispatch(logout());
    history.push("/");
  };

  return (
    <div className={classes.MainContainer}>
      <header className={classes.header}>
        <div className={classes.logo}>
          <img src="/assets/logo.svg" alt="Danone" width="155" height="60" />
        </div>
        <nav className={classes.nav}>
          <ul className={classes.ul}>
            <li>
              <Link
                to="/"
                className={cx({
                  link: true,
                  active: history.location.pathname === "/",
                })}
              >
                Статистика
              </Link>
            </li>
            <li>
              <Link
                to="/mailing"
                className={cx({
                  link: true,
                  active: history.location.pathname.split("/")[1] === "mailing",
                })}
              >
                Рассылки
              </Link>
            </li>
            <li className={classes.logout} onClick={logoutHandler}>
              Выйти
              <div className={classes.logout__img}>
                {/* <Image
                  src="/images/logout.svg"
                  alt="logout"
                  width={20}
                  height={18}
                /> */}
              </div>
            </li>
          </ul>
        </nav>
      </header>
      {/* <div className={classes.loader}>{loading && <LinearProgress />}</div> */}
      <main className={classes.main}>
        <div className={classes.container}>{children}</div>
      </main>
    </div>
  );
};

export default MainContainer;
