import React from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames/bind";

import MainContainer from "../../components/MainContainer/MainContainer";
import { TotalUsers, Events, NewUsers } from "../../components/Statistics/";
import {
  fetchNewUserStats,
  fetchTotalUserStats,
  fetchEvents,
} from "../../store/actions/mainActions";
import { authMe } from "../../store/actions/loginActions";

import classes from "./Statistics.module.scss";

const cx = classNames.bind(classes);

function getFormatDate(date) {
  return date.toISOString().substring(0, 10);
}

const tabs = ["Вчера", "Сегодня", "Неделя", "Месяц"];

export const Statistics = () => {
  const dispatch = useDispatch();
  const { newUsers, totalUsers, events } = useSelector((store) => store.main);

  const [activeTab, setActiveTab] = React.useState(0);

  const now = new Date();
  const yesterday = new Date(new Date().setDate(now.getDate() - 1));
  const weekAgo = new Date(new Date().setDate(now.getDate() - 7));
  const monthAgo = new Date(new Date().setDate(now.getDate() - 30));
  const periods = [yesterday, now, weekAgo, monthAgo];

  React.useEffect(() => {
    dispatch(authMe());

    dispatch(fetchTotalUserStats());
    dispatch(fetchEvents());
  }, []);

  React.useEffect(() => {
    dispatch(
      fetchNewUserStats(getFormatDate(periods[activeTab]), getFormatDate(now))
    );
  }, [activeTab]);

  return (
    <MainContainer>
      <div className={classes.Statistics}>
        {/* Новые пользователи  */}
        <section className={classes.new}>
          <div className={classes.title}>Новые пользователи чат-бота</div>
          <ul className={classes.new__tabs}>
            {tabs.map((tab, index) => (
              <li
                key={index}
                className={cx({
                  new__tab: true,
                  active: index === activeTab,
                })}
                onClick={() => setActiveTab(index)}
              >
                {tab}
              </li>
            ))}
          </ul>
          <NewUsers users={newUsers} />
        </section>

        {/* Всего пользователей  */}
        <TotalUsers users={totalUsers} />
        {/* Популярные действия  */}
        <Events events={events} />
      </div>
    </MainContainer>
  );
};
