import React from "react";
import { Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";

import { getWithExpiry } from "./utils/helpers/localStorageWithExpiry";

import { Login } from "./pages/Login/Login";
import { Statistics } from "./pages/Statistics/Statistics";
import { Mailings } from "./pages/Mailings/Mailings";
import { MailingItem } from "./pages/MailingItem/MailingItem";

export function App() {
  const { isAuth } = useSelector((store) => store.login);
  const token = getWithExpiry("token");

  if (!token) {
    return <Login />;
  }

  return (
    <Switch>
      <Route exact path="/">
        <Statistics />
      </Route>
      <Route exact path="/mailing">
        <Mailings />
      </Route>
      <Route path="/mailing/:id">
        <MailingItem />
      </Route>
    </Switch>
  );
}
