import React from "react";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Box,
  Collapse,
  IconButton,
} from "@material-ui/core";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import {
  getFormatDate,
  getTimeFromDate,
} from "../../../utils/helpers/formatDate";

import classes from "./MailingsRow.module.scss";

export const MailingsRow = ({
  id,
  is_sent,
  is_sending_now,
  send_time,
  emails,
}) => {
  return (
    <TableRow>
      <TableCell align="center">
        {is_sent
          ? "Отправлено"
          : is_sending_now
          ? "Отправляется"
          : "Запланировано"}
      </TableCell>
      <TableCell align="center">
        {getFormatDate(new Date(send_time + "Z"))}&nbsp;
        {getTimeFromDate(new Date(send_time + "Z"))}
      </TableCell>
      <TableCell align="center">
        <div className={classes.flex_cell}>
          <div className={classes.emails_count}>{emails.length}</div>
          <div className={classes.actions}>
            <Link
              to={`/mailing/${id}`}
              classes={{
                root: classes.btn,
              }}
            >
              <ArrowForwardIcon />
            </Link>
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
};
