import React from "react";
import { Dialog, DialogContent, DialogTitle, Button } from "@material-ui/core";

import classes from "./ModalWindow.module.scss";

export function ModalWindow({ title, isOpen, handleClose, children }) {
  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        classes={{
          paper: classes.dialog,
          paperWidthSm: classes.dialog_paper,
        }}
      >
        <DialogTitle className={classes.title}>{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
      </Dialog>
    </div>
  );
}
