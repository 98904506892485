import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import MainContainer from "../../components/MainContainer/MainContainer";
import { AddMailingModal } from "../../components/AddMailingModal/AddMailingModal";
import { ModalWindow } from "../../components/UI/ModalWindow/ModalWindow";
import { MailingsRow } from "../../components/Mailing/MailingsRow/MailingsRow";

import { authMe } from "../../store/actions/loginActions";
import {
  getMailings,
  createMailing,
  clearCurrentMailing,
} from "../../store/actions/mailingActions";

import classes from "./Mailings.module.scss";

export const Mailings = () => {
  const dispatch = useDispatch();

  const { mailings } = useSelector((store) => store.mailing);

  React.useEffect(() => {
    dispatch(authMe());

    dispatch(getMailings());
    dispatch(clearCurrentMailing());
  }, []);

  const [isModalOpen, toggleIsModalOpen] = React.useState(false);

  const openModal = () => {
    toggleIsModalOpen(true);
  };

  const closeModal = () => {
    toggleIsModalOpen(false);
  };

  const sendMailing = (values) => {
    dispatch(createMailing(values));

    closeModal();
  };

  return (
    <MainContainer>
      <section className={classes.Mailings}>
        <div className={classes.add_btn}>
          <Button
            variant="contained"
            className={classes.btn}
            onClick={openModal}
            startIcon={
              <AddIcon
                classes={{
                  root: classes.btn_icon,
                }}
              />
            }
          >
            <span className={classes.btn_text}>Добавить</span>
          </Button>
        </div>
        {mailings ? (
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell width={280} align="center">
                  Статус рассылки
                </TableCell>
                <TableCell width={280} align="center">
                  Дата и время отправки
                </TableCell>
                <TableCell width={280} align="center">
                  Количество пользователей
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mailings.map((item) => (
                <MailingsRow {...item} />
              ))}
            </TableBody>
          </Table>
        ) : (
          <div className={classes.no_cards}>
            <div className={classes.text}>
              Для добавления рассылки нажмите кнопку “Добавить”
            </div>
          </div>
        )}
      </section>
      <ModalWindow
        title={"Новая рассылка"}
        isOpen={isModalOpen}
        handleClose={closeModal}
      >
        <AddMailingModal onSubmit={sendMailing} onCancel={closeModal} />
      </ModalWindow>
    </MainContainer>
  );
};
