import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { Helmet } from "react-helmet";

import { App } from "./App";

import store from "./store";

import "./index.scss";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Provider store={store}>
        <Helmet>
          <title>Чат-бот для Service Now. Административный интерфейс.</title>
          <meta
            name="title"
            content="Чат-бот для Service Now. Административный интерфейс."
          />
        </Helmet>
        <App />
      </Provider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
