import React from "react";
import { TableCell, TableRow } from "@material-ui/core";

import {
  getFormatDate,
  getTimeFromDate,
} from "../../../utils/helpers/formatDate";

import classes from "./MailingItemRow.module.scss";

export const MailingItemRow = ({ email, answer, created_at }) => {
  return (
    <>
      <TableRow>
        <TableCell align="center">{email}</TableCell>
        <TableCell align="center">{answer}</TableCell>
        <TableCell align="center">
          {getFormatDate(new Date(created_at))}&nbsp;
          {getTimeFromDate(new Date(created_at))}
        </TableCell>
      </TableRow>
    </>
  );
};
