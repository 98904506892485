import * as types from "../types";

const initialState = {
  mailings: null,
  currentMailing: null,
  error: null,
  loading: false,
};

export function mailingReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_MAILINGS:
      return { ...state, mailings: action.payload };
    case types.CREATE_MAILING_SUCCESS:
      return { ...state, error: null };
    case types.CREATE_MAILING_ERROR:
      return { ...state, error: action.payload };
    case types.GET_MAILING:
      return { ...state, currentMailing: action.payload };
    case types.GET_MAILING_ERROR:
      return { ...state, error: action.payload };
    case types.UPDATE_MAILING_SUCCESS:
      return { ...state };
    case types.UPDATE_MAILING_ERROR:
      return { ...state, error: action.payload };
    case types.CLEAR_CURRENT_MAILING:
      return { ...state, currentMailing: null };
    case types.SET_LOADING:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
}
