import $api from "../../utils/http";
import * as types from "../types";

export const fetchNewUserStats = (start_date, end_date) => {
  return async (dispatch) => {
    await $api
      .get("/stats/users/new", {
        params: {
          start_date,
          end_date,
        },
      })
      .then((response) => {
        dispatch({ type: types.SET_NEW_USERS_STATS, users: response.data });
      })
      .catch((err) => console.log(err));
  };
};

export const fetchTotalUserStats = () => {
  return async (dispatch) => {
    await $api
      .get("/stats/users/total")
      .then((response) => {
        dispatch({ type: types.SET_TOTAL_USERS_STATS, users: response.data });
      })
      .catch((err) => console.log(err));
  };
};

export const fetchEvents = () => {
  return async (dispatch) => {
    await $api
      .get("/stats/events")
      .then((response) => {
        dispatch({ type: types.SET_EVENTS_DATA, events: response.data });
      })
      .catch((err) => console.log(err));
  };
};
