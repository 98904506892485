import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

import classes from "./Tables.module.scss";

export const Events = React.memo(({ events }) => {
  return (
    <section className={classes.events}>
      <div className={classes.title}>Популярные действия</div>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell width={81} align="center">
              №
            </TableCell>
            <TableCell align="left">Действие</TableCell>
            <TableCell width={220} align="center">
              Workplace
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {events
            ? events.map((row, index) => (
                <TableRow key={index}>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="left">{row.event}</TableCell>
                  <TableCell width={220} align="center">
                    {row.hits_count}
                  </TableCell>
                </TableRow>
              ))
            : null}
        </TableBody>
      </Table>
    </section>
  );
});
